import React, { Dispatch, useEffect, useMemo, useState } from "react";
import { PagesStatusProps } from ".";
import {
  FILE_STATUS,
  MODAL_TYPE,
  STATUS_CODE,
  getLocalDateTimeStringFromISO,
  getOffsetFromPageAndPageSize,
  openNotificationWithIcon,
} from "../../../utils";
import { documentService } from "../../../api/document";
import { ColumnsType } from "antd/es/table";
import { Modal, Table } from "antd";
import { CloseOutlined, EyeOutlined } from "@ant-design/icons";
import { AppPagination, Loading, NotAccessibleFallback } from "../../../components";
import "../../document/document-container.scss";
import { usePagination } from "../../../hooks";
import { useDispatch } from "react-redux";
import { AnyAction } from "redux";
import { openModal, setPageNumberForMedicalEntities } from "../../../store";
const getClass = (value: string) => {
  switch (value) {
    case FILE_STATUS.COMPLETED:
      return "#44c658";
    case FILE_STATUS.ERROR:
      return "#f44336";
  }
};

interface IPageDataType {
  key: string;
  pageNumber: number;
  updatedAt: string;
  status: string;
  message: string;
  medicalEntities: string;
}

const getColumns = (dispatch: Dispatch<AnyAction>): ColumnsType<IPageDataType> => [
  { title: "Page No.", dataIndex: "pageNumber" },
  {
    title: "Message",
    dataIndex: "message",
    render: (value: string) => value.split("\n").map((item, index) => <p key={index}>{item}</p>),
  },
  { title: "Updated At", dataIndex: "updatedAt" },
  {
    title: "Status",
    dataIndex: "status",
    render: (text, value) => (
      <div>
        <div className="status" style={{ color: getClass(value.status) }}>
          <p style={{ color: getClass(value.status) }}>{value.status}</p>{" "}
        </div>
      </div>
    ),
  },
  // {
  //   title: "Medical Entities",
  //   dataIndex: "medicalEntities",
  //   render: (text, value) => (
  //     <div
  //       className="cursor-pointer link-color"
  //       onClick={() => {
  //         dispatch(setPageNumberForMedicalEntities(value.pageNumber.toString()));
  //         dispatch(openModal(MODAL_TYPE.VIEW_MEDICAL_ENTITIES));
  //       }}
  //     >
  //       <EyeOutlined />
  //     </div>
  //   ),
  // },
];

export const PagesStatus = ({ documentId, handleClose, visibility }: PagesStatusProps) => {
  const [pages, setPages] = useState([] as IPage[]);
  const [isLoading, setIsLoading] = useState(false);
  const { onPaginationChange, page, pageSize, pageSizeOptions } = usePagination();
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();
  const [isAuthorized, setIsAuthorized] = useState(true);

  useEffect(() => {
    const fetchPages = async () => {
      setIsLoading(true);
      try {
        const { data } = await documentService.getDocumentPages(
          documentId,
          pageSize,
          getOffsetFromPageAndPageSize(page, pageSize)
        );
        setPages(data.data);
        setTotal(data.pagination?.totalCount ? data.pagination.totalCount : 0);
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon("", err.response.data.message, "error");
          handleClose();
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchPages();
  }, [documentId, handleClose, page, pageSize]);

  const dataSource: IPageDataType[] = useMemo(
    () =>
      pages.map((pageItem) => ({
        key: pageItem.Id.toString(),
        pageNumber: pageItem.PageNumber,
        updatedAt: getLocalDateTimeStringFromISO(pageItem.updatedAt),
        status: pageItem.Status,
        message: pageItem.Message,
        medicalEntities: "",
      })),
    [pages]
  );

  return (
    <Modal
      className="autonomizeModal"
      centered
      width={900}
      visible={visibility}
      footer={null}
      title={"Pages Status"}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="addClient-title no-bd-bt">
        <h1 className="mb-0">Pages Status</h1>
      </div>
      <div className="addClient-content no-pd-tp">
        {isLoading ? (
          <Loading />
        ) : !isAuthorized ? (
          <NotAccessibleFallback />
        ) : (
          <>
            <AppPagination
              className="m-b tr"
              {...{
                showSizeChanger: true,
                current: page,
                total: total,
                pageSize,
                pageSizeOptions,
                onChange: onPaginationChange,
                disabled: isLoading,
              }}
            />
            <Table
              className="document-modal-table"
              columns={getColumns(dispatch)}
              dataSource={dataSource}
              pagination={false}
              loading={isLoading}
            />
          </>
        )}
      </div>
    </Modal>
  );
};
