import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { MODAL_TYPE } from "../../utils";
import DeleteConfirmation from "./delete-confirmation";
import { EditProfile } from "./edit-profile";
import ForgotPassword from "./forgot-password";
import PagesStatus from "./pages-status";
import DocumentProcessingStatus from "./document-processing-status";
import LicenseKey from "./license-key";
import ViewMedicalEntities from "./view-medical-entities";
import Permissions from "./permissions";
export const Modal = () => {
  const { modal } = useSelector((state: RootState) => state.ui);

  return (
    <>
      {modal[MODAL_TYPE.EDIT_PROFILE] && <EditProfile />}
      {modal[MODAL_TYPE.DELETE_CONFIRMATION] && <DeleteConfirmation />}
      {modal[MODAL_TYPE.FORGOT_PASSWORD] && <ForgotPassword />}
      {modal[MODAL_TYPE.PAGES_STATUS] && <PagesStatus />}
      {modal[MODAL_TYPE.DOCUMENT_PROCESSING_DETAIL_STATUS] && <DocumentProcessingStatus />}
      {modal[MODAL_TYPE.LICENSE_KEY] && <LicenseKey />}
      {modal[MODAL_TYPE.VIEW_MEDICAL_ENTITIES] && <ViewMedicalEntities />}
      {modal[MODAL_TYPE.PERMISSIONS] && <Permissions />}
    </>
  );
};
