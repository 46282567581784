import React, { useEffect, useState } from "react";
import { PageWrapper, ServiceStatusContainer } from "../containers";
import { ServiceStatusService } from "../api";
import { openNotificationWithIcon } from "../utils";

export const Dashboard = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [services, setServices] = useState([] as IService[]);

  useEffect(() => {
    const fetchServices = async () => {
      setIsLoading(true);
      try {
        const { data } = await ServiceStatusService.getServices();
        setServices(data.data);
      } catch (err: any) {
        openNotificationWithIcon("", err.response.data.message, "error");
      } finally {
        setIsLoading(false);
      }
    };
    fetchServices();
  }, []);

  return <PageWrapper loading={isLoading} pageContainerChildren={<ServiceStatusContainer services={services}/>} />;
};

export default Dashboard;
