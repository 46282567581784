import React from "react";
import { IconsProps } from "./Icons";

export const ServiceStatusIcon = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 12 14"}
      width={width ? width : "12"}
      height={height ? height : "14"}
      fill={fill ? fill : "currentColor"}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M5.6 0L0 2.1V6.363C0 9.898 2.387 13.195 5.6 14C8.813 13.195 11.2 9.898 11.2 6.363V2.1L5.6 0ZM4.858 9.478L2.38 7L3.367 6.013L4.851 7.497L7.819 4.529L8.806 5.516L4.858 9.478Z"
        fill="currentColor"
      />
    </svg>
  );
};
