import React from "react";
import { Tabs } from "antd";
import { BarChartOutlined } from "@ant-design/icons";
const { TabPane } = Tabs;

export const REQUESTS_TABS = [
  {
    key: "Total",
    icon: <BarChartOutlined />,
  },
];
interface Props {
  onChange: (key: string) => void;
  selectedTab: string;
}

export const RequestTabs = ({ onChange, selectedTab }: Props) => {
  return (
    <div className="tabs m-b">
      <Tabs onChange={onChange} activeKey={selectedTab}>
        {Object.values(REQUESTS_TABS).map((item) => {
          return (
            <TabPane
              tab={
                <div className="flex aic gp-10">
                  {item.icon}
                  <span>{item.key}</span>
                </div>
              }
              key={item.key}
            />
          );
        })}
      </Tabs>
    </div>
  );
};
