import { Input, DatePicker } from "antd";
// import { DatePickerValue } from "antd/es/date-picker/interface";
import React, { useState } from "react";
import "../../../assets/sass/layout/search.scss";

type RangeValue = Parameters<
  NonNullable<React.ComponentProps<typeof DatePicker.RangePicker>["onChange"]>
>[0];
const { RangePicker } = DatePicker;

interface Props {
  onSearchChange: (str: string) => void;
  searchKeyword: string;
}

export const LogsFilter = ({ onSearchChange, searchKeyword }: Props) => {
  const [selectedDateTime, setSelectedDateTime] = useState<RangeValue | null>(null);
  const dateTimeOnChange = (values: RangeValue, formatString: [string, string]): void => {
    setSelectedDateTime(values);
  };
  return (
    <div className="flex aic gp">
      <Input.Search
        style={{ width: 300 }}
        className="record-search"
        placeholder="Search for logs"
        onChange={(e) => !e.target.value && onSearchChange("")}
        onSearch={(e) => {
          onSearchChange(e);
        }}
      />
      <RangePicker
        className="date-range"
        style={{ width: 320 }}
        showTime
        value={selectedDateTime}
        onChange={dateTimeOnChange}
      />
    </div>
  );
};
