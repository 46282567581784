import React from "react";
import { Tabs } from "antd";
import { BarChartOutlined, SlidersOutlined, ThunderboltOutlined } from "@ant-design/icons";
const { TabPane } = Tabs;

export const TOKEN_TABS = [
  {
    key: "Total",
    icon: <BarChartOutlined />,
  },
  {
    key: "Context",
    icon: <SlidersOutlined />,
  },
  {
    key: "Generated",
    icon: <ThunderboltOutlined />,
  },
];

interface Props {
  onChange: (key: string) => void;
  selectedTab: string;
}

export const TokenTabs = ({ onChange, selectedTab }: Props) => {
  return (
    <div className="tabs m-b">
      <Tabs onChange={onChange} activeKey={selectedTab}>
        {Object.values(TOKEN_TABS).map((item) => {
          return (
            <TabPane
              tab={
                <div className="flex gp-10 aic">
                  {item.icon}
                  <span>{item.key}</span>
                </div>
              }
              key={item.key}
            />
          );
        })}
      </Tabs>
    </div>
  );
};
