import React, { useEffect, useMemo, useState } from "react";
import { Column, ColumnConfig } from "@ant-design/charts";
import { tokenBillingService } from "../../../api/tokenBilling";
import { STATUS_CODE, formatLargeNumber, openNotificationWithIcon } from "../../../utils";
import { NotAccessibleFallback } from "../../../components";

export const TokensUsage = () => {
  const [loading, setLoading] = useState(false);
  const [tokenUsageData, setTokenUsageData] = useState([] as ITokenUsage[]);
  const [tokenUsedThisMonth, setTokenUsedThisMonth] = useState("0");
  const [isAuthorized, setIsAuthorized] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data } = await tokenBillingService.getData();
        const tokenData: ITokenUsage[] = [];
        let tokenThisMonth = 0;
        data.data.forEach((item, index) => {
          item.token_count.forEach((entry) => {
            if (index === data.data.length - 1) tokenThisMonth += entry.token_count;
            tokenData.push({
              month: item.date,
              type: entry.service_name,
              value: entry.token_count,
            });
          });
        });
        setTokenUsedThisMonth(formatLargeNumber(tokenThisMonth));
        setTokenUsageData(tokenData);
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon("", err.response.data.message, "error");
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const config: ColumnConfig = useMemo(() => {
    return {
      loading,
      data: tokenUsageData,
      isStack: true,
      xField: "month",
      yField: "value",
      seriesField: "type",
      label: {
        content: "",
        position: "middle",
        layout: [
          { type: "interval-adjust-position" },
          { type: "element-active" },
          { type: "element-highlight-by-color" },
        ],
      },
      columnStyle: {
        radius: 0,
      },
      color: ["#F43F5F", "#F59E0C", "#3C82F6"],
    };
  }, [loading, tokenUsageData]);

  if (!isAuthorized) {
    return <NotAccessibleFallback />;
  }

  return (
    <div className="ant-card">
      <h4>{`Usage (tokens)`}</h4>
      <p>{`${tokenUsedThisMonth} tokens consumed this month`}</p>
      <Column {...config} />
    </div>
  );
};
