import { apiClient } from "./apiClient";

const getDocuments = async (reqObj: IGetDocumentsRequest) => {
  const res = await apiClient.get<IGetDocumentsResponse>(
    `/document-process/list?limit=${reqObj.limit}&offset=${reqObj.offset}`
  );
  return res;
};

const getDocumentPages = async (documentUUID: string, limit: number, offset: number) => {
  const res = await apiClient.get<IGetDocumentPagesResponse>(
    `/document-process/status?document_uuid=${documentUUID}&limit=${limit}&offset=${offset}`
  );
  return res;
};

const getDocumentMedicalEntitiesByPage = async (documentId: string, pageNumber: string) => {
  const res = await apiClient.get<IGetDocumentMedicalEntitiesByPageResponse>(
    `/search-medical-entities?documentId=${documentId}&pageNumber=${pageNumber}`
  );
  return res;
};

export const documentService = {
  getDocuments,
  getDocumentPages,
  getDocumentMedicalEntitiesByPage,
};
