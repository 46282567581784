import React from "react";
import { IconsProps } from "./Icons";

export const CircularCancelIcon = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 36 36"}
      width={width ? width : "36"}
      height={height ? height : "36"}
      fill={fill ? fill : "none"}
    >
      <g xmlns="http://www.w3.org/2000/svg" clipPath="url(#clip0_8232_6782)">
        <path
          d="M18 0C19.6523 0 21.2461 0.210938 22.7812 0.632812C24.3164 1.05469 25.7461 1.66406 27.0703 2.46094C28.3945 3.25781 29.6074 4.19531 30.709 5.27344C31.8105 6.35156 32.7539 7.56445 33.5391 8.91211C34.3242 10.2598 34.9277 11.6953 35.3496 13.2188C35.7715 14.7422 35.9883 16.3359 36 18C36 19.6523 35.7891 21.2461 35.3672 22.7812C34.9453 24.3164 34.3359 25.7461 33.5391 27.0703C32.7422 28.3945 31.8047 29.6074 30.7266 30.709C29.6484 31.8105 28.4355 32.7539 27.0879 33.5391C25.7402 34.3242 24.3047 34.9277 22.7812 35.3496C21.2578 35.7715 19.6641 35.9883 18 36C16.3477 36 14.7539 35.7891 13.2188 35.3672C11.6836 34.9453 10.2539 34.3359 8.92969 33.5391C7.60547 32.7422 6.39258 31.8047 5.29102 30.7266C4.18945 29.6484 3.24609 28.4355 2.46094 27.0879C1.67578 25.7402 1.07227 24.3047 0.650391 22.7812C0.228516 21.2578 0.0117188 19.6641 0 18C0 16.3477 0.210938 14.7539 0.632812 13.2188C1.05469 11.6836 1.66406 10.2539 2.46094 8.92969C3.25781 7.60547 4.19531 6.39258 5.27344 5.29102C6.35156 4.18945 7.56445 3.24609 8.91211 2.46094C10.2598 1.67578 11.6953 1.07227 13.2188 0.650391C14.7422 0.228516 16.3359 0.0117188 18 0ZM19.9863 18L25.998 11.9883L24.0117 10.002L18 16.0137L11.9883 10.002L10.002 11.9883L16.0137 18L10.002 24.0117L11.9883 25.998L18 19.9863L24.0117 25.998L25.998 24.0117L19.9863 18Z"
          fill="currentColor"
        />
      </g>
      <defs xmlns="http://www.w3.org/2000/svg">
        <clipPath id="clip0_8232_6782">
          <rect width="36" height="36" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};
