import React, { useState } from "react";
import { SERVICE_STATUS } from "../../../utils";
import {
  ChatIcon,
  CircularCancelIcon,
  CircularCheckIcon,
  DataIcon,
  EditIcon,
  InformationIcon,
  MailIcon,
  StopwatchIcon,
  ThreeDotIcon,
  WaitingClockIcon,
} from "../../../assets/icons";
import { Card, Dropdown, Menu } from "antd";
import "./service-card.scss";
import { MinusCircleFilled } from "@ant-design/icons";

interface Props {
  service: IService;
  disabled?: boolean;
}

const getServiceStatusColorandIcon = (service: IService) => {
  let res = { color: "#C55B5B", icon: <CircularCancelIcon /> };
  if (service.statusCode === 200) res = { color: "#75A977", icon: <CircularCheckIcon /> };
  if (service.statusCode === 700) res = { color: "#777777", icon: <MinusCircleFilled /> };
  return res;
  // switch (service.statusCode) {
  // case SERVICE_STATUS.READY:
  //   return { color: "#75A977", icon: <CircularCheckIcon /> };
  // case SERVICE_STATUS.PROCESSING:
  //   return { color: "#E08949", icon: <StopwatchIcon /> };
  // case SERVICE_STATUS.UPDATING:
  //   return { color: "#75A977", icon: <CircularCheckIcon /> };
  // case SERVICE_STATUS.FAILED:
  //   return { color: "#C55B5B", icon: <CircularCancelIcon /> };
  // default:
  //   return { color: "#B277CD", icon: <WaitingClockIcon /> };
  // }
};

// const menu = (
//   <Menu>
//     <Menu.Item key="option1">Send Request</Menu.Item>
//     <Menu.Item key="option2">Email to Autonomize</Menu.Item>
//   </Menu>
// );

const ServiceCard = ({ service, disabled }: Props) => {
  // const [showMenu, setShowMenu] = useState(false);
  const { icon, color } = getServiceStatusColorandIcon(service);

  // const getPopupContainer = (triggerNode: HTMLElement) => {
  //   if (triggerNode && triggerNode.parentNode instanceof HTMLElement) {
  //     return triggerNode.parentNode;
  //   }
  //   return document.body;
  // };

  return (
    <Card className={`service-card ${disabled ? "disabled-item" : ""}`} style={{ color }}>
      {/* <div className="tr">
        <Dropdown
          className="tr m-b"
          overlay={menu}
          trigger={["click"]}
          visible={showMenu}
          placement="bottomRight"
          onVisibleChange={() => setShowMenu(!showMenu)}
          getPopupContainer={getPopupContainer}
        >
          <ThreeDotIcon className="cursor-pointer link-color" />
        </Dropdown>
      </div> */}
      <div className="service-card-icon">{icon}</div>
      <div className="flex aic gp-10">
        <h2>{service.serviceName}</h2>
        <div className="flex aic" title={service.description}>
          <InformationIcon />
        </div>
      </div>
      <p className="service-status-text">{service.message}</p>
      {/* <p>{service.description}</p> */}
    </Card>
  );
};

export default ServiceCard;
