import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PageWrapper, EvideceViewerAside, EvidenceViewerContainer } from "../containers";
import { useFetchEvidenceViewer, useFetchSearchResult } from "../hooks";
import "../containers/evidence-viewer/evidence-viewer-container/evidence-viewer-container.scss";
import { NotAccessibleFallback } from "../components";

const EvidenceViewer = () => {
  const [searchParams] = useSearchParams();
  const { fetchSearchResults, searchLoading, searchResult } = useFetchSearchResult();
  const [pageSearchKey, setPageSearchKey] = useState<string | null>(null);
  const [searchKeywords, setSearchKeywords] = useState<string | null>(null);

  const { fileId, initialSearchKeywords, multipleFiles, tileType } = useMemo(() => {
    setSearchKeywords(searchParams.get("search"));
    return {
      fileId: searchParams.get("fileId") || "",
      initialSearchKeywords: searchParams.get("search"),
      multipleFiles: searchParams.get("multipleFiles"),
      tileType: searchParams.get("tile_type"),
    };
  }, [searchParams]);

  const {
    fetchFile,
    fetchPage,
    currPage,
    fileData,
    occurencesList,
    pageLoading,
    searchOccurenceLoading,
    pageData,
    setCurrPage,
    fetchSearchOccurences,
    isAuthorized,
    isTiffFile,
  } = useFetchEvidenceViewer({ fileId, searchKeywords });

  useLayoutEffect(() => {
    if (tileType) {
      const find = occurencesList.findIndex((ele) => ele.pageNumber === currPage);
      if (find !== -1) {
        setPageSearchKey(occurencesList[find].content.slice(0, tileType === "lifestyle" ? 14 : 20));
      }
    }
  }, [currPage, occurencesList, tileType]);

  useEffect(() => {
    if (multipleFiles === "true" && !searchResult.data) {
      fetchSearchResults("?limit=1000&offset=0");
    }
  }, [fetchSearchResults, multipleFiles, searchResult.data]);

  useEffect(() => {
    if (fileId) {
      fetchFile();
    }
  }, [fetchFile, fileId]);

  useEffect(() => {
    if (fileId) {
      fetchPage();
    }
  }, [fetchPage, fileId]);

  useEffect(() => {
    if (((searchKeywords && searchKeywords.length > 0) || tileType) && fileId)
      fetchSearchOccurences(tileType);
  }, [fetchSearchOccurences, fileId, searchKeywords, tileType]);

  if (!isAuthorized) {
    return <PageWrapper pageContainerChildren={<NotAccessibleFallback />} />;
  }

  return (
    <>
      <PageWrapper
        pageLeftAsideChildren={
          <EvidenceViewerContainer
            {...{
              currPage,
              evidenceContentLoading: pageLoading || searchLoading,
              fileId,
              // initialSearchKeywords,
              // occurencesList,
              pageData,
              pageSearchKey,
              pdfUrl: fileData.url,
              searchKeywords,
              isTiffFile,
              fileUrl: fileData.url,
              // searchResultOccurrenceLoading: searchOccurenceLoading || searchLoading,
              setCurrPage,
              // setSearchKeywords,
              // tileType,
            }}
          />
        }
        pageContainerChildren={
          <EvideceViewerAside
            {...{
              currPage,
              setCurrPage,
              fileId,
              pageData,
              searchLoading,
              searchResult,
              searchKeywords,
              setSearchKeywords,
              tileType,
              searchResultOccurrenceLoading: searchOccurenceLoading || searchLoading,
              occurencesList,
              initialSearchKeywords,
            }}
          />
        }
      />
    </>
  );
};

export default EvidenceViewer;
