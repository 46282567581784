import React from "react";
import { IconsProps } from "./Icons";

export const CircularCheckIcon = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 36 36"}
      width={width ? width : "36"}
      height={height ? height : "36"}
      fill={fill ? fill : "none"}
    >
      <mask
        xmlns="http://www.w3.org/2000/svg"
        id="mask0_8229_7459"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="36"
        height="36"
      >
        <path
          d="M18 34C20.1015 34.0027 22.1829 33.5901 24.1245 32.7858C26.066 31.9816 27.8295 30.8015 29.3136 29.3136C30.8016 27.8295 31.9816 26.066 32.7858 24.1245C33.5901 22.1829 34.0027 20.1015 34 18C34.0027 15.8984 33.59 13.8171 32.7858 11.8755C31.9815 9.93397 30.8015 8.17048 29.3136 6.68639C27.8295 5.19842 26.066 4.0184 24.1245 3.21415C22.1829 2.4099 20.1015 1.99728 18 2C15.8985 1.99732 13.8171 2.40996 11.8755 3.21421C9.93399 4.01845 8.17049 5.19845 6.68641 6.68639C5.19847 8.17048 4.01847 9.93397 3.21422 11.8755C2.40997 13.8171 1.99733 15.8984 2.00001 18C1.99729 20.1015 2.40991 22.1829 3.21416 24.1245C4.01841 26.066 5.19843 27.8295 6.68641 29.3136C8.17049 30.8015 9.93399 31.9815 11.8755 32.7858C13.8171 33.59 15.8985 34.0026 18 34Z"
          fill="white"
          stroke="white"
          strokeWidth="4"
          strokeLinejoin="round"
        />
        <path
          d="M11.6 18L16.4 22.8L26 13.2"
          stroke="black"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </mask>
      <g xmlns="http://www.w3.org/2000/svg" mask="url(#mask0_8229_7459)">
        <path d="M-1.19995 -1.2H37.2V37.2H-1.19995V-1.2Z" fill="currentColor" />
      </g>
    </svg>
  );
};
