import { Dispatch } from "redux";
import { RootState, closeModal } from "../../../store";
import { MODAL_TYPE } from "../../../utils";
import { connect } from "react-redux";
import { PagesStatus } from "./PagesStatus";

type StateProps = {
  visibility: boolean;
  documentId: string;
};

type DispatchProps = {
  handleClose: () => void;
};

export type PagesStatusProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
  visibility: state.ui.modal[MODAL_TYPE.PAGES_STATUS],
  documentId: state.document.documentIDForPagesStatus,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(closeModal(MODAL_TYPE.PAGES_STATUS));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PagesStatus);
