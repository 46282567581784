import React from "react";
import { PAGE_URL, USER_ROLES_CODE } from "../../../utils";
import {
  ServiceStatusIcon,
  LogsIcon,
  RBCAIcon,
  APIKeysIcon,
  LicenseIcon,
  MLIcon,
  DeploymentIcon,
  ThemeConfigIcon,
  CohortIcon,
} from "../../../assets/icons";
import { DashboardOutlined } from "@ant-design/icons";

// const ADMIN_LEFT_MENU_ITEMS: ILeftMenuItem[] = [
//   {
//     link: PAGE_URL.DASHBOARD,
//     icon: <HomeIcon />,
//     title: "Dashboard",
//   },
// ];

const USER_LEFT_MENU_ITEMS: ILeftMenuItem[] = [
  {
    link: PAGE_URL.DASHBOARD,
    icon: <ServiceStatusIcon />,
    title: "Service Status",
  },
  // {
  //   link: PAGE_URL.LOGS,
  //   // eslint-disable-next-line react/jsx-no-undef
  //   icon: <LogsIcon />,
  //   title: "Logs",
  // },
  {
    link: PAGE_URL.RBAC,
    icon: <RBCAIcon />,
    title: "RBAC",
  },
  // {
  //   link: PAGE_URL.API_KEYS,
  //   icon: <APIKeysIcon />,
  //   title: "API Keys",
  // },
  // {
  //   link: PAGE_URL.LICENSE_MANAGEMENT,
  //   icon: <LicenseIcon />,
  //   title: "License",
  // },
  {
    link: PAGE_URL.ML_CONFIGURATION,
    icon: <MLIcon />,
    title: "ML Config",
  },
  // {
  //   link: PAGE_URL.DEPLOYMENT,
  //   icon: <DeploymentIcon />,
  //   title: "Deployment",
  // },
  // {
  //   link: PAGE_URL.THEME_CONFIGURATION,
  //   icon: <ThemeConfigIcon />,
  //   title: "Theme Config",
  // },
  {
    link: PAGE_URL.DOCUMENT,
    icon: <CohortIcon />,
    title: "Documents",
  },
  {
    link: PAGE_URL.BILLING_DASHBOARD,
    icon: <DashboardOutlined />,
    title: "Billing Dashboard",
  },
];

export const getAppMenuItems = (roleCode: string) => {
  // const menu = [] as ILeftMenuItem[];
  // switch (roleCode) {
  //   case USER_ROLES_CODE.ADMIN:
  //     menu.push(...ADMIN_LEFT_MENU_ITEMS);
  //     break;
  //   case USER_ROLES_CODE.BUSINESS_USER:
  //     menu.push(...USER_LEFT_MENU_ITEMS);
  //     break;
  //   case USER_ROLES_CODE.DATA_SCIENTIST:
  //     menu.push(...USER_LEFT_MENU_ITEMS);
  //     break;
  // }
  // return menu;

  return USER_LEFT_MENU_ITEMS;
};
