import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IDocumentSliceInitialState {
  documentIDForPagesStatus: string;
  pageNumber: string;
  documentDetails: IDocument;
}

export const documentInitialState: IDocumentSliceInitialState = {
  documentIDForPagesStatus: "",
  documentDetails: {} as IDocument,
  pageNumber: "",
};

export const documentSlice = createSlice({
  name: "document",
  initialState: documentInitialState,
  reducers: {
    setDocumentIdFOrPagesStatus: (
      state: IDocumentSliceInitialState,
      { payload }: PayloadAction<string>
    ) => {
      state.documentIDForPagesStatus = payload;
    },
    setDocumentDetail: (
      state: IDocumentSliceInitialState,
      { payload }: PayloadAction<IDocument>
    ) => {
      state.documentDetails = { ...payload };
    },
    setPageNumberForMedicalEntities: (
      state: IDocumentSliceInitialState,
      { payload }: PayloadAction<string>
    ) => {
      state.pageNumber = payload;
    },
  },
});

export const { setDocumentIdFOrPagesStatus, setDocumentDetail, setPageNumberForMedicalEntities } =
  documentSlice.actions;
export default documentSlice.reducer;
