import { useCallback, useState } from "react";
import { usePagination } from "./usePagination";
import { SorterResult } from "antd/es/table/interface";
import { STATUS_CODE, getOffsetFromPageAndPageSize, openNotificationWithIcon } from "../utils";
import { documentService } from "../api/document";

interface ColumnSort extends SorterResult<any> {
  sortOrder: "asc" | "desc" | null;
}

export const useFetchDocuments = () => {
  const { page, onPaginationChange, pageSize, pageSizeOptions } = usePagination();
  const [sort, setSort] = useState({} as ColumnSort);
  const [isLoading, setIsLoading] = useState(false);
  const [documents, setDocuments] = useState([] as IDocument[]);
  const [total, setTotal] = useState(0);
  const [isAuthorized, setIsAuthorized] = useState(true);

  const onSortChange = (newSort: any) => {
    if (newSort.order === "ascend") setSort({ ...newSort, sortOrder: "asc" });
    else if (newSort.order === "descend") setSort({ ...newSort, sortOrder: "desc" });
    else setSort({ ...newSort, sortOrder: null });
  };

  const getDocumentsFilterObject = useCallback(() => {
    let obj: IGetDocumentsRequest = {
      limit: pageSize,
      offset: getOffsetFromPageAndPageSize(page, pageSize),
    };
    if (sort.sortOrder && sort.field) {
      obj = { ...obj, sortField: sort.field.toString(), sortType: sort.sortOrder };
    }
    return { ...obj };
  }, [page, pageSize, sort.field, sort.sortOrder]);

  const fetchDocuments = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await documentService.getDocuments(getDocumentsFilterObject());
      setDocuments(data.data);
      setTotal(data.pagination.totalCount ? data.pagination.totalCount : 0);
    } catch (err: any) {
      if (err.response.status === STATUS_CODE.FORBIDDEN) {
        setIsAuthorized(false);
      } else {
        openNotificationWithIcon("", err.response.data.message, "error");
      }
    } finally {
      setIsLoading(false);
    }
  }, [getDocumentsFilterObject]);

  return {
    paginationObj: { onPaginationChange, pageSizeOptions, page, pageSize },
    sortFilter: { sort, setSort: onSortChange },
    getDocumentsFilterObject,
    fetchDocuments,
    isDocumentsLoading: isLoading,
    documents,
    totalDocuments: total,
    setDocuments,
    setTotalDocuments: setTotal,
    isAuthorized,
  };
};
