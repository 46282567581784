import axios, { AxiosRequestConfig } from "axios";
import { store } from "../App";
import { logoutUser } from "../store";
import { PAGE_URL } from "../utils";
import { ActiveDirectoryService } from "./activeDirectoryAuth";

export const authHeaders = (headers: any, token: string): any => {
  const namespace = "authorization";
  const privateNamespace = "x-user-token";
  headers[namespace] = `Bearer ${token}`;
  headers[privateNamespace] = "true";
  return headers;
};

export const withAuthHeaders = (headers: any): any => {
  const token = store.getState().authUser.user.accessToken;
  return authHeaders(headers, token || "");
};

const defaultOptions = {
  baseURL: window._env_.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
};

const apiClient = axios.create(defaultOptions);

apiClient.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    //   set user token here
    config.headers = withAuthHeaders({});
    return config;
  },
  (error: any) => {
    Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (res) => res,
  async (error: any) => {
    // handle user session refresh here
    const pathname = window.location.pathname;
    if (pathname !== PAGE_URL.LOGIN && error.response.status === 401) {
      // store.dispatch(logoutUser());
      // window.location.replace(PAGE_URL.LOGIN);
      const { data } = await ActiveDirectoryService.activeDirectoryLogout(window.location.origin);
      if (data) {
        store.dispatch(logoutUser());
        window.location.assign(data.data.logoutUri);
      }
    }
    if (error.response.status >= 500) {
      error.response.message = "Something went wrong!";
    }
    if (error.response.status !== 401) {
      error.response.message = "";
    }
    return Promise.reject(error);
  }
);

export { apiClient };
