import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { EvidencePageResponseInfo, SearchOccurrence } from "../../api";
import { Loading, PdfViewer } from "../../components";
import { useAuthUser } from "../../hooks";
import { DATA_ADMIN_PERMISSIONS, SEARCH_KEYWORD_DELIMINATOR } from "../../utils";
import {
  SearchResultOccurrences,
  EvidenceContent,
  FeedbackForm,
  Legends,
  CATEGORY_COLOR_MAPPING,
} from "./evidence-viewer-container";
import TiffViewer from "../../components/tiff-viewer/TiffViewer";

const { TabPane } = Tabs;

type EnhancedViewerTabType = {
  [key: string]: string;
};

export const ENHANCED_VIEW_TABS: EnhancedViewerTabType = {
  PAGE: "AI Assisted Review",
  // OCR: 'OCR Output',
  // MEDICAL_ENTITIES: 'Medical Entities',
  // NORMAL_ENTITIES: 'Normal Entities',
  JSON: "JSON Output",
};

interface Props {
  searchKeywords: string | null;
  currPage: number;
  setCurrPage: React.Dispatch<React.SetStateAction<number>>;
  // setSearchKeywords: React.Dispatch<React.SetStateAction<string | null>>;
  // tileType: string | null;
  // searchResultOccurrenceLoading: boolean;
  // occurencesList: SearchOccurrence[];
  // initialSearchKeywords: string | null;
  isTiffFile: boolean;
  fileUrl: string;
  evidenceContentLoading: boolean;
  pageData: EvidencePageResponseInfo;
  pageSearchKey: string | null;
  fileId: string;
}

export const EvidenceViewerContainer = (props: Props) => {
  const {
    searchKeywords,
    currPage,
    setCurrPage,
    // setSearchKeywords,
    // tileType,
    // searchResultOccurrenceLoading,
    // initialSearchKeywords,
    // occurencesList,
    evidenceContentLoading,
    fileUrl,
    isTiffFile,
    pageData,
    fileId,
    pageSearchKey,
  } = props;

  const [selectedTab, setSelectedTab] = useState(ENHANCED_VIEW_TABS.PAGE);
  const [selectedLegends, setSelectedLegends] = useState([] as string[]);
  const { roleCode } = useAuthUser();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const insightType = queryParams.get("insight_type");
    if (insightType) {
      setSelectedLegends((prevSelectedLegends) => [...prevSelectedLegends, insightType]);
    }
  }, [location.search]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const searchTerm = queryParams.get("search");
    const categories = [] as string[];
    if (searchTerm && Object.keys(pageData).length !== 0) {
      const terms = searchTerm.toLowerCase().split(SEARCH_KEYWORD_DELIMINATOR);
      pageData.medicalEntities.forEach((entity) => {
        terms.forEach((term) => {
          if (entity.Text.toLowerCase().includes(term)) {
            categories.push(entity.Category);
          }
        });
      });
    }
    const filteredCategories = [...new Set(categories)].filter((item) =>
      Object.keys(CATEGORY_COLOR_MAPPING).includes(item)
    );
    setSelectedLegends((prevSelectedLegends) => [...prevSelectedLegends, ...filteredCategories]);
  }, [location.search, pageData.medicalEntities, pageData]);

  const onChange = (key: string) => {
    setSelectedTab(key);
  };

  return (
    <div>
      <div className="tabs">
        <Tabs onChange={onChange} defaultActiveKey={selectedTab}>
          {Object.keys(ENHANCED_VIEW_TABS).map((key) => (
            <TabPane tab={ENHANCED_VIEW_TABS[key]} key={ENHANCED_VIEW_TABS[key]} />
          ))}
        </Tabs>
      </div>
      {selectedTab === ENHANCED_VIEW_TABS.PAGE && !evidenceContentLoading && (
        <Legends {...{ selectedLegends, setSelectedLegends }} />
      )}
      <div className="evidence-page">
        <EvidenceContent
          pageInfo={pageData}
          loading={evidenceContentLoading}
          selectedTab={selectedTab}
          searchKeywords={pageSearchKey || searchKeywords || ""}
          selectedLegends={selectedLegends}
        />
        {fileUrl ? (
          isTiffFile ? (
            <TiffViewer
              tiff={fileUrl}
              pageInfo={{ height: 700, width: 400 }}
              setCurrPage={setCurrPage}
              currPage={currPage}
            />
          ) : (
            <PdfViewer
              pdf={fileUrl}
              pageInfo={{ height: 700, width: 400 }}
              displayPagination
              setCurrPage={setCurrPage}
              currPage={currPage}
            />
          )
        ) : (
          <Loading />
        )}
      </div>
      {/* <FeedbackForm pageNo={pageData.pageNumber} fileUUID={fileId} /> */}
    </div>
  );
};
