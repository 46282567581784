import React from "react";
import ServiceCard from "./service-status-container/ServiceCard";
import { InformationIcon } from "../../assets/icons";

interface Props {
  services: IService[];
}

const untrackedServices: IService[] = [
  {
    description:
      "Service handling all frontend communication and directing request to right service.",
    message: "UP and RUNNING - The service is available and working.",
    serviceName: "BFF (Backend for frontend)",
    statusCode: 700,
  },
  {
    description: "Service handling clinical data management and analysis.",
    message: "UP and RUNNING - The service is available and working.",
    serviceName: "Clinical LLM",
    statusCode: 700,
  },
  {
    description: "Service handling document processing and management.",
    message: "UP and RUNNING - The service is available and working.",
    serviceName: "Document Processor",
    statusCode: 700,
  },
  {
    description: "Service handling connecting and linking icd10 data.",
    message: "UP and RUNNING - The service is available and working.",
    serviceName: "ICD10 Linking",
    statusCode: 700,
  },
  {
    description: "Service handling flexible data storage and retrieval.",
    message: "UP and RUNNING - The service is available and working.",
    serviceName: "Flexstore",
    statusCode: 700,
  },
  {
    description: "Service handling extraction and processing of hedis measures.",
    message: "UP and RUNNING - The service is available and working.",
    serviceName: "Hedis Measure Extractor",
    statusCode: 700,
  },
  {
    description: "Service handling ML label creation and management.",
    message: "UP and RUNNING - The service is available and working.",
    serviceName: "ML Label Studio",
    statusCode: 700,
  },
  {
    description: "Service handling tracking and monitoring ML models.",
    message: "UP and RUNNING - The service is available and working.",
    serviceName: "ML Tracking Server",
    statusCode: 700,
  },
  {
    description: "User interface for Mosaic app.",
    message: "UP and RUNNING - The service is available and working.",
    serviceName: "Mosaic Frontend",
    statusCode: 700,
  },
  {
    description: "User interface for Wizard app.",
    message: "UP and RUNNING - The service is available and working.",
    serviceName: "Wizard Frontend",
    statusCode: 700,
  },
  {
    description: "User interface for Admin app.",
    message: "UP and RUNNING - The service is available and working.",
    serviceName: "Admin Panel Frontend",
    statusCode: 700,
  },
];

export const ServiceStatusContainer = (props: Props) => {
  const { services } = props;

  return (
    <div>
      <>
        <div className="flex aic gp-10 m-b service-status">
          <h4 className="service-status-title">Tracking Status - Active</h4>
          <div title="These services are monitored in our system" className="flex aic">
            <InformationIcon />
          </div>
        </div>
        <div className="service-card-group">
          {services.length === 0 ? (
            <div>No Services Exists</div>
          ) : (
            <>
              {services.map((service, id) => (
                <ServiceCard key={`service-${id}`} {...{ service }} />
              ))}
            </>
          )}
        </div>
      </>
      <>
        <div className="flex aic gp-10 m-b service-status">
          <h4 className="service-status-title">Tracking Status - Inactive</h4>
          <div
            title="Currently these services are not monitored in our system. Updates coming soon!"
            className="flex aic"
          >
            <InformationIcon />
          </div>
        </div>
        <div className="service-card-group">
          {untrackedServices.length === 0 ? (
            <div>No Services Exists</div>
          ) : (
            <>
              {untrackedServices.map((service, id) => (
                <ServiceCard key={`service-${id}`} {...{ service }} disabled />
              ))}
            </>
          )}
        </div>
      </>
    </div>
  );
};
